<template>
  <section class="widget">
    <div class="grid grid-pad">
      <div class="col-1-2 push-1-4 sm-col-10-12 sm-push-1-12 xs-col-1-1 xs-push-0">
        <h1 class="page-title">{{ $t("Create a ticket") }}</h1>
        <create-ticket-form :default-country="country" :default-retailer="retailer"/>
      </div>
    </div>
  </section>
</template>

<script>
import CreateTicketForm from '@/components/Forms/CreateTicket.vue'

export default {
  name: 'Widget-CreateTicket',
  components: {
    CreateTicketForm
  },
  data () {
    return {
      country: null,
      retailer: null
    }
  },
  beforeCreate () {
    this.$store.commit('setSettingWidget', true)
  },
  mounted () {
    if (this.$route.query.country) {
      this.country = parseInt(this.$route.query.country)
      sessionStorage.setItem('widget_country', this.$route.query.country)

      if (this.$route.query.retailer) {
        this.retailer = parseInt(this.$route.query.retailer)
        sessionStorage.setItem('widget_retailer', this.$route.query.retailer)
      }
    } else if (sessionStorage.getItem('widget_country')) {
      this.country = parseInt(sessionStorage.getItem('widget_country'))
      if (sessionStorage.getItem('widget_retailer')) {
        this.retailer = parseInt(sessionStorage.getItem('widget_retailer'))
      }
    }

    // set color
    if (this.$route.query.color) {
      document.documentElement.style.setProperty('--portal-color', decodeURI(this.$route.query.color))
      sessionStorage.setItem('widget_color', decodeURI(this.$route.query.color))
    } else if (sessionStorage.getItem('widget_color')) {
      document.documentElement.style.setProperty('--portal-color', sessionStorage.getItem('widget_color'))
    }
  },
  computed: {
  },
  methods: {
  },
  watch: {
  }
}
</script>
